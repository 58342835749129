import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import ReactMarkdown from "react-markdown"
import Helmet from "react-helmet";
import ReCAPTCHA from "react-google-recaptcha";

import CustomLink from "../components/CustomLink";
import Layout from "../components/Layout";
import ContactFormContent from "../components/ContactFormContent";


class ServicesHero extends React.Component {
  render() {
    const content = this.props.page.frontmatter;

    return (
      <div>
        <img className="service-hero-image" src="/img/service-hero-illustration.svg" alt="Services"/>
        <div className="content-wrapper padding-vertical-m service-page-title flex-column justc-center
                                        s-padding-vertical-s">
          <h1 className="title-3 section-title left ">
            {content.heroTitle}
          </h1>
        </div>
      </div>
    )
  }
}

export const ServiceContactForm = props => {
  return (
    <form className="flex-column alitems-stretch" 
          name="services-page-contact"
          method="post" 
          data-netlify="true"
          data-netlify-honeypot="bot-field"
          data-netlify-recaptcha="true"
          action="/contact-confirmation/">
        <input type="hidden" name="form-name" value="services-page-contact" />
        <input type="hidden" name="bot-field" />

        <h3 className="title-5 mgb-m
                       m-mgb-s m-left-text pdr-m">
          Obtenir des informations sur notre offre&nbsp;:
          <span className="blue-underline">{props.contactSubject}</span>
        </h3>

        <input className="hidden" type="text" name="service-name" value={props.contactSubject}/>

        <ContactFormContent formName="service-page-contact" color="light"/>
        <div className="full-width flex-row justc-center mgt-m">
          <ReCAPTCHA sitekey={process.env.GATSBY_RECAPTCHA_KEY}/>
        </div>
      </form>
  )
}


class ServicesList extends React.Component {
  constructor(props) {
    super(props)

    this.state = { 
      contactSubject: "",
      contactFormIsDisplayed: ""
    };
  }

  toggleContactForm(e) {
    if (this.state.contactFormIsDisplayed === "displayed") {
      this.setState({contactFormIsDisplayed: ""})
    } else {
      this.setState({contactFormIsDisplayed: "displayed"})
    } 
  }

  escFunction = event => {
    if ((event.keyCode === 27) && (this.state.contactFormIsDisplayed === "displayed")) {
      event.preventDefault();
      this.setState({ contactFormIsDisplayed: "" })
    }}

  setContactSubject(event, type) {
    this.setState({contactSubject: type});
  }

  stopPropagationCustom(e) {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
  }

  componentDidUpdate() {
    if (this.state.contactFormIsDisplayed === "displayed") {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "visible";
    }
  }

  componentDidMount(){
    document.addEventListener("keydown", this.escFunction, false);
  }

  componentWillUnmount() {
    document.body.style.overflow = "visible";
    document.removeEventListener("keydown", this.escFunction, false);
  }

  render() {
    const content = this.props.page.frontmatter;

    return (
      <div>
        <div className="content-wrapper">
          {content.serviceslist.map((service, index) => (
            <div key={index} className="service-section">
              <div className="service-illu">
                <img src={service.illustration} alt='illustration service' />
              </div>
              <div className="service-text s-mgt-m">
                <h3 className="title-4 text-light bold-weight mgb-s">
                  <ReactMarkdown children={service.title}/>
                </h3>
                <ReactMarkdown children={service.description} className="arrow-list paragraph-spacing"/>
                {service.ispage 
                ? <CustomLink 
                    linkType="internal"
                    linkURL={service.link}
                    className="cta fill blue mgt-m">
                    Plus d'informations
                  </CustomLink>
                : <button className="cta fill blue mgt-m"
                        onClick={(e) => {this.setContactSubject(e, service.callToAction.mailSubject); 
                                         this.toggleContactForm()}}>
                  {service.callToAction.title}
                  </button>}
              </div>
            </div>
          ))}
        </div>
        
        <div className={`popup-contact-wrapper ${this.state.contactFormIsDisplayed}`} 
             onClick={(e) => {this.toggleContactForm()}}
             // Prevent Eslint from generating useless warnings while preventing tab index
            role="button" tabIndex="-1" onKeyDown={this.escFunction}>
          <div className="popup-contact-form" onClick={this.stopPropagationCustom}
               // Prevent Eslint from generating useless warnings, while preventing tab index
               role="button" tabIndex="-1" onKeyDown={this.escFunction}>
            <ServiceContactForm contactSubject={this.state.contactSubject}/>
            <button className="close-contact-form" onClick={(e) => {this.toggleContactForm()}}>
              <span>Fermer</span>
            </button>
          </div>
        </div>

      </div>
    )
  }
}



export const ServicesPageTemplate = props => {
  const {page} = props;

  return (
    <div>
      <ServicesHero page={page}/>
      <ServicesList page={page}/>
    </div>
  );
};

const ServicesPage = ({ data }) => {
  const { markdownRemark: page, footerData, navbarData } = data;
  const {
    frontmatter: {
      seo: { title: seoTitle, description: seoDescription, browserTitle},
    },
  } = page;

  return (
    <Layout footerData={footerData} navbarData={navbarData}>
      <Helmet>
        <meta name="title" content={seoTitle} />
        <meta name="description" content={seoDescription} />
        <title>{browserTitle}</title>
      </Helmet>
      <ServicesPageTemplate page={page} />
    </Layout>
  );
};

ServicesPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default ServicesPage;

export const servicesPageQuery = graphql`
  query ServicesPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        heroTitle
        serviceslist {
          ispage
          link
          illustration
          title
          description
          callToAction {
            title
            mailSubject
          }
        }
        seo {
          browserTitle
          title
          description
        }
      }
    }
    ...LayoutFragment
  }
`;